

import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Button from '../components/Button';

import ProductCard from '../components/ProductCard';
import Layout from '../components/layout/Layout';


import ProductsData from '../data/products-data';
import LeadForm from "../components/LeadForm";
import SplitSectionB2b from '../components/SplitSectionB2b';
import SplitSection from '../components/SplitSection';
import NewsletterSub from "../components/NewsletterSub";
import { Link } from 'gatsby';

const siteVerOpts = [

  {
    label: 'Ecuador',
    value: '',


  },

  {
    label: 'Internacional',
    value: '/export/es/productos/',


  },
];

const langOpts = [


  {
    label: 'ES',
    value: '',

  },


  {
    label: 'EN',
    value: `/export/en/products/`,

  },


];



const seo={
  title: "Productos de limpieza",
  desc:  "Fabricamos y comercializamos productos líderes del mercado, reconocidos por su calidad, innovación, y excelente costo en uso. Adaptamos nuestra oferta de productos a las necesidades específicas de cada cliente, a través de un asesoramiento profesional, seguimiento y entrenamiento permanentes.",
  image: "productos.jpg",
  pathname: "/productos/",
  alternateEn: "/export/en/products/",
  alternateEs: "/export/es/productos/",
  alternateEsec: "/productos/",
  alternateDefault: "/export/es/productos/"
}

const Productos = ({location}) => (

  <Layout location={location} crumbLabel="Productos"
  siteVerOpts={siteVerOpts} langOpts={langOpts}
  alternates alternateEn={seo.alternateEn} alternateEs={seo.alternateEs} alternateEsec={seo.alternateEsec} alternateDefault={seo.alternateDefault}
  title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>


<section id="productos" className="mt-14">
              <div className="">
               <h1 className="text-5xl font-semibold text-center mb-2">Productos de Limpieza </h1>
                <h2 className="text-4xl font-semibold text-center mb-8 text-primary-lighter">Fabricamos y comercializamos productos de la más alta calidad </h2>
              </div>
            </section>






<section id="productos" className="mt-14">
<div className="relative bg-blue-900">
  <div className="h-56 bg-blue-900 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
  <StaticImage src="../images/prueba-banner-productos.jpg"
                      className="flex justify-center "
                      alt="Productos de alta calidad para exportación"
                      placeholder="blurred"
                      layout="constrained"
                       />
  </div>
  <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16 pt-28">
    <div className="md:ml-auto md:w-1/2 md:pl-10">
      <p className=" text-2xl text-leftfont-normal text-white pb-6">Disponemos de un portafolio completo de productos de limpieza para resolver todas sus necesidades de higiene institucional. <br/><br/> Encuentre productos líderes del mercado, reconocidos por su calidad, innovación, y excelente costo en uso.<br/>Adaptamos nuestra oferta de productos a las necesidades específicas de cada cliente, a través de un asesoramiento profesional, seguimiento y entrenamiento permanentes.</p>
    </div>
  </div>
</div>
</section>




<section id="categorias productos" className=" mt-20 pb-10">
  <h2 className="text-primary-default  title-font text-center text-5xl font-semibold  ">Portafolio de Productos de Limpieza </h2>
         
    <div className="relative bg-white px-4 sm:px-6 lg:pt-6 lg:pb-28 lg:px-8">
     <div className="relative max-w-7xl mx-auto mt-10">
      <div className=" max-w-lg mx-auto grid gap-8 lg:grid-cols-4 lg:max-w-none">

        <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
          <div className="flex-1 bg-white p-6 flex flex-col justify-between">
            <div className="flex-1">
              <StaticImage src="../images/categoria-productos-quimicos.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestra amplia línea de dispensadores"
                    placeholder="blurred"
                    layout="constrained"/>

                <div className="flex flex-wrap mx-auto justify-center">
                  <Link to="/productos/quimicos/" className="text-3xl font-semibold text-secondary-lighter text-center leading-relaxed">Productos Químicos</Link>
                </div>
              <p className="text-primary-default text-lg pt-2">Productos químicos altamente concentrados para la limpieza y desinfección de superficies.</p>
               <div className="flex flex-wrap mx-auto justify-left">
                 <Link to="/productos/quimicos/" className="mt-4 text-primary-lighter inline-flex items-center text-lg text-semibold">Más información
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
              </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            <StaticImage src="../images/categoria-absorbentes-ecuador.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestros productos profesionales para higiene de manos"
                    placeholder="blurred"
                    layout="constrained" />
              <div className="flex flex-wrap mx-auto justify-center">
                  <Link to="/productos/absorbentes" className="text-3xl font-semibold text-secondary-lighter text-center leading-relaxed">Absorbentes</Link>
                </div>
              <p className="text-primary-default text-lg pt-2">Absorbentes fabricados con materia prima de primera calidad que garantizan absorción, suavidad y resistencia.</p>
              <div className="flex flex-wrap mx-auto justify-left">
                <Link to="/productos/absorbentes" className="mt-4 text-primary-lighter inline-flex items-center text-lg text-semibold">Más información
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
           <StaticImage src="../images/categoria-higiene-de-manos-ecuador.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestros productos de alta calidad para la industria alimenticia"
                    placeholder="blurred"
                    layout="constrained" />
              <div className="flex flex-wrap mx-auto justify-center ">
                  <Link to="/productos/higiene-de-manos/" className="text-3xl font-semibold text-secondary-lighter text-center">Higiene de Manos</Link>
                </div>
              <p className="text-primary-default text-lg pt-2">Productos de alta calidad para el lavado y desinfección de manos: gel antibacterial, jabón, alcohol.</p>
              <div className="flex flex-wrap mx-auto justify-left ">
                <Link to="/productos/higiene-de-manos/" className="mt-4 text-primary-lighter inline-flex items-center text-lg text-semibold">Más información
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div> 
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
           <StaticImage src="../images/categoria-lavanderia-ecuador.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestros productos de alta calidad para la desinfección de superficies"
                    placeholder="blurred"
                    layout="constrained" />
              <div className="flex flex-wrap mx-auto justify-center ">
                  <Link to="/productos/lavanderia/" className="text-3xl font-semibold text-secondary-lighter text-center">Lavandería </Link>
                </div>
              <p className="text-primary-default text-lg pt-2">Detergentes y suavizantes desarrollados con fórmulas biodegradables y sin fosfato ideal para el lavado y cuidado textil.</p>
              <div className="flex flex-wrap mx-auto justify-left ">
                <Link to="/productos/lavanderia/" className="mt-4 text-primary-lighter inline-flex items-center text-lg text-semibold">Más información
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div> 
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
           <StaticImage src="../images/categoria-dispensadores-ecuador.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestra línea de absorbentes"
                    placeholder="blurred"
                    layout="constrained" />
              <div className="flex flex-wrap mx-auto justify-center ">
                  <Link to="/productos/dispensadores/" className="text-3xl font-semibold text-secondary-lighter text-center">Dispensadores </Link>
                </div>
              <p className="text-primary-default text-lg pt-2">Equipos para dispensar papel higiénico, jabón, toallas de manos resistentes y elegantes. </p>
              <div className="flex flex-wrap mx-auto justify-left ">
                <Link to="/productos/dispensadores/" className="mt-4 text-primary-lighter inline-flex items-center text-lg text-semibold">Más información
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div> 
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
           <StaticImage src="../images/categoria-secadores-de-manos-ecuador.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestra línea de absorbentes"
                    placeholder="blurred"
                    layout="constrained" />
              <div className="flex flex-wrap mx-auto justify-center ">
                  <Link to="/productos/secadores-de-manos/" className="text-3xl font-semibold text-secondary-lighter text-center">Secadores de Manos </Link>
                </div>
              <p className="text-primary-default text-lg pt-2">Secadores rápidos de manos para evitar el contacto con otras superficies entre el lavado y secado.</p>
              <div className="flex flex-wrap mx-auto justify-left ">
                <Link to="/productos/secadores-de-manos/" className="mt-4 text-primary-lighter inline-flex items-center text-lg text-semibold">Más información
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div> 
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
           <StaticImage src="../images/categoria-linea-profesional-ecuador.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestra línea de absorbentes"
                    placeholder="blurred"
                    layout="constrained" />
              <div className="flex flex-wrap mx-auto justify-center ">
                  <Link to="/productos/linea-profesional/" className="text-3xl font-semibold text-secondary-lighter text-center">Línea profesional </Link>
                </div>
              <p className="text-primary-default text-lg pt-2">Implementos profesionales de limpieza de alta calidad para asegurar la limpieza adecuada de su entorno profesional. Encuentre mopas de barrido, escurridor de piso, etc.</p>
              <div className="flex flex-wrap mx-auto justify-left ">
                <Link to="/productos/linea-profesional/" className="mt-4 text-primary-lighter inline-flex items-center text-lg text-semibold">Más información
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div> 
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
           <StaticImage src="../images/categoria-seguridad-ocupacional-ecuador.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestra línea de absorbentes"
                    placeholder="blurred"
                    layout="constrained" />
              <div className="flex flex-wrap mx-auto justify-center ">
                  <Link to="/productos/seguridad-ocupacional/" className="text-3xl font-semibold text-secondary-lighter text-center">Seguridad Ocupacional</Link>
                </div>
              <p className="text-primary-default text-lg pt-2">Equipos de protección personal para la seguridad física de sus colaboradores. Encuentre mascarillas, guantes de nitrilo.</p>
              <div className="flex flex-wrap mx-auto justify-left ">
                <Link to="/productos/seguridad-ocupacional/" className="mt-4 text-primary-lighter inline-flex items-center text-lg text-semibold">Más información
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div> 
        </div>
      </div>

     </div>
    </div>
    <div className="pt-20 ">
      <a href="../files/catalogo-institucional.pdf" className="" target="_blank" rel="noopener">
              <Button className="flex mx-auto text-white bg-primary-lighter border-0 py-2 px-8 rounded transition duration-200 ease-in-out bg-primary-default shadow-md hover:bg-secondary-lighter hover:shadow-lg transform hover:-translate-y-1 hover:scale-105 focus:outline-none"  >DESCARGUE NUESTRO CATÁLOGO INSTITUCIONAL </Button></a>
     </div>
  </div>
</section>













<SplitSectionB2b/>

<LeadForm/>

<NewsletterSub/>

</Layout>

)
export default Productos
